import { useContext } from 'react'
import { Link } from 'gatsby'
import ArrowLink from 'src/images/icons/ArrowLink'
import { useMobile } from 'src/hooks/useMobile'
import { maskCurrency } from 'src/utils/convertCurrency'
import { AccountContext } from 'src/components/account/context'

import SliderComponent from './components/SliderComponent'
import CashbackIcon from '../../../images/icons/Cashback'
import ArrowWhite from '../../../images/icons/ArrowWhite'
import bgCashback from '../../../images/bgCashback.png'
import bgCashbackMob from '../../../images/bgCashbackMob.png'

import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export type DecodedToken = {
  access_token: string
  exp: number
  iat: number
}

export type CashbackData = {
  data: {
    balance: number
  }
}

type CashbackProps = {
  trackEvent: (trackEvent: string) => void
}

const Cashback = ({ trackEvent }: CashbackProps) => {
  const { cashBackBalance } = useContext(AccountContext)
  const { isMobile } = useMobile()

  return (
    <div className="flex flex-wrap items-center gap-x-4 w-[full] sm:w-[700px] mt-[40px] ">
      <div className="w-full flex justify-between items-center mb-[15px]">
        <span className="font-inter text-[16px]  text-[#101010] text-base leading-5 ">
          Clube Decathlon
        </span>

        <Link
          to="/clube"
          className="flex items-center"
          onClick={() => trackEvent('Saiba mais sobre o clube')}
        >
          <span className="mr-[4px] font-inter text-[12px] leading-5 text-left text-[#3E4751]">
            Saiba mais sobre o clube
          </span>
          <ArrowLink />
        </Link>
      </div>

      <div className="block h-full sm:h-[441px] sm:flex ">
        <div
          className="relative w-full sm:w-[288px] bg-[#0073C6]  text-[#fff] rounded-[8px] z-10 w-5/5 pt-[32px] pb-[15px]"
          style={{
            backgroundImage: `url(${isMobile ? bgCashbackMob : bgCashback})`,
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '55% auto',
          }}
        >
          <span className="ml-[20px] font-inter text-[12px] leading-5 block">
            Saldo cashback
          </span>
          <div className="mt-[3px] flex items-center font-medium text-[32px] leading-10 tracking-normal ml-[20px]">
            <span className="mr-[5px]">
              {maskCurrency(cashBackBalance ?? 0)}
            </span>
            <CashbackIcon />
          </div>
          <div className="border-[1px] border-dashed border-[#B3BAC3] w-full mt-[25px] mb-[19px]" />

          <div className="flex items-center ml-[20px]">
            <Link
              to="/cashback"
              onClick={() =>
                trackEvent('Saiba como usar seu saldo de cashback')
              }
            >
              <span className="font-inter  text-[12px] font-normal leading-5 ">
                Saiba como usar seu saldo
              </span>
            </Link>

            <ArrowWhite />
          </div>

          <img
            className="mt-[40px] mb-[15px] w-[292px] h-[196px] sm:w-[237px] sm:h-[216px]"
            src={
              isMobile
                ? 'https://decathlonstoreapp.vteximg.com.br/arquivos/bgCashbackPeopleMob.png'
                : 'https://decathlonstoreapp.vteximg.com.br/arquivos/bgCashbackPeople.png'
            }
            alt="People"
            style={{ objectFit: 'contain' }}
          />
        </div>

        <SliderComponent trackEvent={trackEvent} />
      </div>
    </div>
  )
}

export default Cashback
